/* StudentCount.css */
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main-content {
    padding: 20px;
    overflow: hidden;
}

.table-responsive {
    overflow-x: auto;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.thead-dark th {
    background-color: #343a40;
    color: #fff;
}

.pagination {
    margin-top: 20px;
}

.alert-info {
    margin-bottom: 20px;
}
/* StudentsClass.css */

.students-class-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .students-class-title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .class-select-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .class-select-label {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 500;
  }
  
  .class-select {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    transition: border-color 0.3s ease;
  }
  
  .class-select:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .students-list-container {
    margin-top: 30px;
  }
  
  .students-list-title {
    font-size: 20px;
    font-weight: 500;
    color: #444;
    margin-bottom: 15px;
  }
  
  .students-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .students-table th, .students-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .students-table th {
    background-color: #f5f5f5;
    font-weight: 600;
  }
  
  .students-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .no-students {
    text-align: center;
    font-size: 16px;
    color: #999;
    margin-top: 20px;
  }
  
  .students-list-container {
    animation: fadeIn 0.5s ease-in-out;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  