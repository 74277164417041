.attendance-container {
    margin-left: 200px;
  }
  
  .attendance-form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-control {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
  }
  
  .btn {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
  }
  
  .attendance-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .attendance-table th,
  .attendance-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .attendance-table th {
    background-color: #f2f2f2;
  }
  .attendance-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.attendance-modal-overlay {
  background: rgba(0, 0, 0, 0.75);
}

.attendance-form .form-group {
  margin-bottom: 15px;
}

.attendance-form .form-control {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.attendance-form .btn {
  margin-right: 10px;
}
/* Add this to your CSS file */

.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.form-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: calc(100% - 40px); /* Ensure it doesn't exceed viewport width */
  width: 100%;
  margin-left: 200px; /* Initial margin */
}
.container-attendace{
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: calc(100% - 200px); /* Ensure it doesn't exceed viewport width */
  margin-left: 200px; /* Initial margin */
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .form-container {
    margin-left: 0; /* Remove margin on smaller screens */
  }
  .container-attendace{
    margin-left: 0;
  }
}