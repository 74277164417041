/* Resetting default browser styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Sidebar styles */
.side-container,
.sidebar-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    height: calc(100% - 60px); 
    border-right: 1px solid #dee2e6;
    overflow-y: auto;
    padding: 1rem;
    transition: transform 0.5s ease-in-out;
    transform: translateX(-100%);
}

.sidebar-container {
    width: 200px;
    background-color: rgba(18, 18, 203, 0.578);
    box-shadow: 0px 0px 10px rgba(0, 0, 10, 0.2);
}

.side-container {
    width: 90px;
}

.sidebar-container.visible,
.side-container.visible {
    transform: translateX(0);
}

.sidebar-container::-webkit-scrollbar,
.side-container::-webkit-scrollbar {
    display: none;
}

/* Sidebar link styles */
.sidebar .nav-link {
    color: black;
}

.sidebar .nav-link:hover {
    background-color: #1f65ac;
    color: black;
}

/* Remove scrollbar for IE, Edge, and Firefox */
.sidebar-container,
.side-container {
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}

/* Responsive sidebar */
@media (max-width: 980px) {
    .sidebar-container,
    .side-container {
        transform: translateX(-100%);
    }
    .sidebar-container.visible,
    .side-container.visible {
        transform: translateX(0);
    }
}
/* Table and dashboard styles */
.table-responsive {
    margin-left: 180px;
    max-width: 100%;
}
@media (max-width: 700px) {
    .table-responsive {
        margin-left: 0;
    }
}

/* Dashboard container styles */
.dashboard-container {
    width: calc(100% - 200px);
    margin-left: 190px;
    padding: 10px;
}
@media (max-width: 980px) {
    .dashboard-container {
        width: 90%;
        margin: auto;
        padding: 10px;
    }
}
/* Additional styles */
.upper-Hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    box-shadow: 0px 4px 8px rgba(81, 78, 78, 0.1);
    flex-wrap: wrap;
}
.card {
    background-color: rgba(18, 18, 203, 0.578);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
}
